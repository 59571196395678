import IUser from '@/types/IUser';

export default class AdminUsersFactory {
  static toUser(data: any): IUser {
    return {
      active: data.active,
      companyId: data.companyId,
      companyName: data.companyName,
      firstName: data.firstName,
      id: data.id,
      lastName: data.lastName,
      mail: data.mail,
    };
  }
}
