import axios from 'axios';
import IUserForm from '@/types/IUserForm';
import IUsersListFilters from '@/types/IUsersListFilters';
import {IPagination} from '@/types/IPagination';

export default class AdminUserRepository {
  // TODO: pass params
  static async getAll(params: IUsersListFilters | IPagination): Promise<any> {
    let mail = '';
    let page = 1;
    let size = 10;
    if ('mail' in params) {
      mail = params.mail || '';
    }
    if ('page' in params) {
      page = params.page;
    }
    if ('size' in params) {
      size = params.size;
    }
    return axios.get(`admin/user/all/${page}/${size}/${mail}`);
  }

  static toggleStatus(userId: string | number, active: boolean): Promise<any> {
    return axios.patch(`admin/user/edit-status/${userId}/${active}`);
  }

  static update(userId: string | number, form: IUserForm) {
    return axios.put(`admin/user/edit-data/${userId}`, form);
  }
}
