




















import {Component, Vue} from 'vue-property-decorator';
import IUser from '@/types/IUser';
import AdminUsersService from '@/services/AdminUsersService';
import {resolveError} from '@/utils/notifications';
import UsersList from '@/components/AdminUsers/UsersList.vue';
import UsersFilters from '@/components/AdminUsers/UsersFilters.vue';
import IUsersListFilters from '@/types/IUsersListFilters';
import {IPagination} from '@/types/IPagination';
import PageWrap from '@/components/PageWrap.vue';

@Component({
  name: 'Users',
  components: {
    PageWrap,
    UsersFilters,
    UsersList,
  },
})
export default class Users extends Vue {
  users: IUser[] = [];
  loading: boolean = false;

  params: IUsersListFilters = {
    mail: '',
  };
  pagination: IPagination = {
    page: 1,
    size: 10,
    totalElements: 0,
  };

  created() {
    // this.fetchData()
  }

  async fetchData() {
    try {
      this.loading = true;
      const {
        users,
        totalElements,
      } = await AdminUsersService.getAll({...this.params, ...this.pagination});
      this.users = users;
      this.pagination.totalElements = totalElements;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  onFiltersChanged(filters: IUsersListFilters) {
    this.params = {...this.params, ...filters};
    this.fetchData();
  }
}
