import IUser from '../types/IUser';
import AdminUserRepository from '../repositories/AdminUserRepository';
import AdminUsersFactory from '@/factories/AdminUsersFactory';
import IUserForm from '@/types/IUserForm';
import IUsersListFilters from '@/types/IUsersListFilters';
import {IPagination} from '@/types/IPagination';

export default class AdminUsersService {
    static async getAll(params: IUsersListFilters | IPagination): Promise<{ users: IUser[], totalElements: number }> {
        const {data} = await AdminUserRepository.getAll(params);
        return {
            users: data.content.map((user: any) => AdminUsersFactory.toUser(user)),
            totalElements: data.totalElements,
        };

    }

    static toggleStatus(userId: string | number, active: boolean): Promise<void> {
        return AdminUserRepository.toggleStatus(userId, active);
    }

    static update(userId: string | number, form: IUserForm) {
        return AdminUserRepository.update(userId, form);
    }
}
